
import Button from '~/components/elements/Button';
import ProductCard from '~/components/catalog/productCard/index.vue';
import Banner from '~/components/catalog/banner';

export default {
  name: 'ProductSection',
  components: {Banner, ProductCard, Button},
  props: {
    title: {
      type: [String, null],
      default: null,
    },
    products: {
      type: Array,
      required: true,
    },
    btnLink: {
      type: String,
      default: null,
    },
    banner: {
      type: Object,
      default: null,
    },
    bannerLeft: {
      type: Boolean,
      default: false,
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
}
